<template>
  <div class="play">
    <div class="play-header" :style="{ paddingTop: 35 + statusBarHeight + 'px' }">
      <Back 
        :clickHandle="handleClosePage"
        :innerText="localesPackage?.['yuli_back']?.[localesEnv]"
      />
    </div>
    <div class="play-content">
      <div class="play-content-describe">
        <h1 class="play-content-describe-title">
          <img
            :src="
              require('@/assets/images/YuliApp/howToPlay/icon_breed_on.svg')
            "
          />{{ playDetail.title }}
        </h1>
        <p class="play-content-describe-content">
          {{ playDetail.content }}
        </p>
      </div>
      <div class="play-content-formOne">
        <img
          class="play-content-formTwo-bg"
          :src="imgFrom1"
        />
      </div>
      <div class="play-content-formTwo">
        <img
          class="play-content-formTwo-bg"
          :src="imgFrom2"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { onBeforeMount, onMounted, ref } from "vue";
import appBridging from "@/utils/appBridging.js";
import {getYuliHowToPlayLanguage} from "@/api/language.js";
import store from "@/store";
import Back from "@/components/universal/Back";

export default {
  components: {
    // DiceDetail,
    // SkillLevel,
    // SkillCombination,
    Back
  },
  computed: {
    ...mapState({
      init: (state) => state.global.init,
      statusBarHeight: (state) => state.global.statusBarHeight,
      languageEnv: (state) => state.global.languageEnv,
      languagePackage: (state) => state.global.languagePackage,
    }),
  },
  watch: {
    languageEnv: {
      async handler(value) {
        console.log('languageEnv: ', value);
        if(value){
          this.imgInitHandle(value);
          this.localesEnv = value;
        }
      },
      immediate: true,
      deep: true,
    },
    languagePackage: {
      async handler(value) {
        console.log('languagePackage: ', value);
        if(value){
          this.localesPackage = value;
          this.initHandle();
        }
      },
      immediate: true,
      deep: true,
    }
  },
  setup() {
    const playDetail = ref([]);
    const combinData = ref([]);
    const levelData = ref([]);

    /**语言包 - ref */
    const localesPackage = ref({});
    /**语言环境 - ref */
    const localesEnv = ref('en');
    const imgFrom1 = ref(require(`@/assets/images/YuliApp/howToPlay/v2/img_form1_en.png`));
    const imgFrom2 = ref(require(`@/assets/images/YuliApp/howToPlay/v2/img_form2_en.png`));

    onBeforeMount(async ()=>{
      appBridging.getSettingLaungage();
      const res = await getYuliHowToPlayLanguage();
      store.dispatch("global/setLanguagePackage", res);// 存到stroe
    })

    onMounted(() => {
      appBridging.getStatusBarHeight();
      
    });

    const imgInitHandle = (env, defaultEnv = 'en') => {
      try {
        imgFrom1.value = require(`@/assets/images/YuliApp/howToPlay/v2/img_form1_${env}.png`);
      } catch (error) {
        imgFrom1.value = require(`@/assets/images/YuliApp/howToPlay/v2/img_form1_${defaultEnv}.png`);
      }
      try {
        imgFrom2.value = require(`@/assets/images/YuliApp/howToPlay/v2/img_form2_${env}.png`);
      } catch (error) {
        imgFrom2.value = require(`@/assets/images/YuliApp/howToPlay/v2/img_form2_${defaultEnv}.png`);
      }
    }

    const initHandle = () => {
      const localesPackageLocal = localesPackage.value;
      const localesEnvLocal = localesEnv.value;
      playDetail.value = {
        title: localesPackageLocal?.['purify_label']?.[localesEnvLocal],
        content: localesPackageLocal?.['purify_content']?.[localesEnvLocal],
      };

      appBridging.closeLoading();
    }

    /**
     * 关闭页面
     */
    const handleClosePage = () => {
      appBridging.closePage();
    };

    return {
      handleClosePage,
      initHandle,
      playDetail,
      combinData,
      levelData,
      localesPackage,
      localesEnv,
      imgInitHandle,
      imgFrom1,
      imgFrom2
    };
  },
};
</script>

<style lang="less" scoped>
h1,
p {
  padding: 0;
  margin: 0;
}
.play {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  // background: linear-gradient(180deg, #352d62ff 0%, #2d64a5ff 100%);
  background-image: url("~@/assets/images/YuliApp/howToPlay/page_bg.png");
  background-size: 100% 100%;
  &-header {
    position: relative;
    width: 100%;
    height: 30px;
    // background-image: url("~@/assets/images/YuliApp/howToPlay/header_bg.png");
    // background-size: 100% 100%;

    &-block {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 30px;
      &-title {
        position: absolute;
        // left: 50%;
        top: 0;
        // transform: translate(-50%, 0);
        right: 15px;
        height: 30px;
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        font-weight: 500;
        font-family: "PingFang SC";
        text-align: right;
        line-height: 28.8px;
      }
    }
  }
  &-content {
    height: 95%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    // background-image: url("~@/assets/images/YuliApp/howToPlay/content_bg.png");
    // background-size: cover;
    // padding-top: 33px;
    padding-bottom: 20px;
    &-describe {
      width: 344.18px;
      margin: 0 auto;
      // margin-top: 32px;
      // margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-title {
        width: 100%;
        height: 26px;
        // color: #E6B59A;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        font-family: "PingFang HK";
        text-align: left;
        line-height: 26px;
        // padding-right: 60px;
        margin-bottom: 12px;
        img {
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }
      }
      &-content,
      &-tips {
        text-indent: 28px;
        width: 100%;
        // color: #9EB1D7;
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
        font-weight: 400;
        font-family: "PingFang HK";
        text-align: left;
        line-height: 21px;

        margin-bottom: 24px;
      }
      &-tips {
        margin-bottom: 8px;
      }
      &-describeOne {
        height: 19px;
        color: rgba(128, 142, 182, 1);
        font-size: 12px;
        font-weight: 400;
        font-family: "PingFang HK";
        text-align: left;
        line-height: 19.2px;

        margin-bottom: 8px;
        img {
          width: 12px;
          height: 12px;
          vertical-align: middle;
          margin-right: 3.5px;
        }
      }
    }

    //隐藏滚动条
    .play-content-block::-webkit-scrollbar {
      display: none;
    }
    .play-content-block {
      scrollbar-width: none;
      -ms-overflow-style: none;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &-formOne {
      position: relative;
      width: 344.18px;
      height: 328.27px;
      margin: auto;
      margin-bottom: 28px;
      // background-image: url("~@/assets/images/YuliApp/howToPlay/v2/img_form1.png");
      // background-size: 100% 100%;
      &-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        top: 0;
        left: 0;
      }
      &-large {
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 12.7px;
        right: 10.18px;
      }
    }

    &-formTwo {
      position: relative;
      width: 344.18px;
      height: 328.27px;
      margin: auto;
      margin-bottom: 10px;
      // background-image: url("~@/assets/images/YuliApp/howToPlay/v2/img_form2.png");
      // background-size: 100% 100%;
      &-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        top: 0;
        left: 0;
      }
      &-large {
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 12.7px;
        right: 10.18px;
      }
    }
  }
}
</style>
