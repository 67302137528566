<template>
  <div class="page">
    <!-- 返回 -->
    <!-- <div class="back" :style="{ top: 10 + statusBarHeight + 'px' }" @click="handleClosePage">
      <img :src="require('@/assets/images/YuliApp/button_return.svg')" />
      <div class="back-title">How To Play</div>
    </div>
    <img class="title" :src="require('@/assets/images/YuliApp/howToPlay/title.png')" />
    <div class="content">
      <img
        class="content-img"
        :src="require('@/assets/images/YuliApp/howToPlay/png_word.png')"
        alt=""
      />
    </div> -->
    <YuliHowToPlay></YuliHowToPlay>
  </div>
</template>
<script>
import { onBeforeMount, onMounted, ref } from "vue";
import { mapState } from "vuex";
import appBridging from "@/utils/appBridging.js";
import YuliHowToPlay from "@/components/rules/YuliHowToPlay"
export default {
  components: {
    YuliHowToPlay
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped></style>